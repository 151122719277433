import BaseBean from "@/utils/BaseBean";

interface ITreeProps {
    disabled:any
    children: string
    label: string
}
interface ITopMenuTree {
    topMenus:Array<{menuId:string,title:string}>
    trees:Array<{parentId:string,treeData:any}>
}
export interface IRoleMenuDataObj {
    utilInst:RoleMenuUtil,
    treeRef: any
    compParams:any
    defaultProps:ITreeProps
    topMenuTree: any
    activeMenuId: string
    treeData: Array<any>
    disabled:boolean
    menuSelPermissionItems:any
    menuSelKeys:Array<string>
    roleId: string
    engineInst:any
    form: any
}
export default class RoleMenuUtil extends BaseBean{
    public dataObj:IRoleMenuDataObj
    constructor(proxy:any,dataObj:IRoleMenuDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }


    public async reloadRoleMenuInfo(engineInst:any):Promise<void>{
        let res = await this.utils.Api.loadRoleMenu({ roleId: engineInst.engineParams.id,modelType:'card'});
        engineInst.buttons=res.buttons;
        let allMenus = JSON.parse(res.allMenus);
        this.buildTopMenuTree(allMenus);
        this.dataObj.menuSelPermissionItems = res.mapMenuPermissions;
        this.dataObj.menuSelKeys = res.selMenus;
        this.dataObj.treeRef.setCheckedKeys(res.selMenus);
        this.dataObj.roleId = engineInst.engineParams.id;
        this.dataObj.engineInst=engineInst;
        this.dataObj.disabled=res.disabled;
    }

    public buildTopMenuTree(allMenus:any):void{
        let topMenuTree:{
            topMenus:Array<{menuId:string,title:string}>,
            trees:Array<{parentId:string,treeData:any}>
        } = {
            topMenus: [],
            trees: []
        };

        for (let i = 0; i < allMenus.length; i++) {
            let menuItem = allMenus[i];
            menuItem.menuItemPermission =allMenus[i].menuItemPermission;
            topMenuTree.topMenus.push({menuId:menuItem.id,title:menuItem.title});
            topMenuTree.trees.push({parentId:menuItem.id,treeData:menuItem});
        }
        this.dataObj.topMenuTree = topMenuTree;
        this.dataObj.activeMenuId = this.dataObj.topMenuTree.topMenus[0].menuId;
        this.dataObj.treeData = [this.dataObj.topMenuTree.trees[0].treeData];
        this.dataObj.treeRef.props.disabled=()=>{return this.dataObj.disabled};
    }
    public switchMenu(menuId:string):void{
        if (menuId == this.dataObj.activeMenuId) return;
        let treeNode = this.dataObj.treeRef.data;
        let checkedKeys = this.dataObj.treeRef.getCheckedKeys();
        this.addSelKeys();
        this.removeUnSelKeys(treeNode, checkedKeys);

        this.dataObj.activeMenuId = menuId;
        let trees = this.dataObj.topMenuTree.trees;
        for (let i = 0; i < trees.length; i++) {
            if (menuId == trees[i].parentId) {
                this.dataObj.treeData = [trees[i].treeData];
                break;
            }
        }
        this.dataObj.treeRef.setCheckedKeys(this.dataObj.menuSelKeys);
        this.dataObj.treeRef.props.disabled=()=>{return this.dataObj.disabled};
    }
    public addSelKeys():void{
        let checkedKeys = this.dataObj.treeRef.getCheckedKeys();
        for (let i = 0; i < checkedKeys.length; i++) {
            if (this.dataObj.menuSelKeys.indexOf(checkedKeys[i]) == -1) {
                this.dataObj.menuSelKeys.push(checkedKeys[i]);
            }
        }
    }
    public removeUnSelKeys(nodes:any, checkedKeys:any):void{
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];

            if (checkedKeys.indexOf(node.id) == -1 && this.dataObj.menuSelKeys.indexOf(node.id) > -1) {
                this.dataObj.menuSelKeys.splice(this.dataObj.menuSelKeys.indexOf(node.id), 1);
            }
            if (node.children) {
                this.removeUnSelKeys(node.children, checkedKeys);
            }
        }
    }
    public getSelMenuAndPermissionData():any{
        let treeNode = this.dataObj.treeRef.data;
        let checkedKeys = this.dataObj.treeRef.getCheckedKeys();
        this.dataObj.utilInst.addSelKeys();
        this.dataObj.utilInst.removeUnSelKeys(treeNode, checkedKeys);
        return {
            menuSelKeys: this.dataObj.menuSelKeys,
            menuSelPermissionItems: this.dataObj.menuSelPermissionItems
        };
    }
    public selAllPermission(data:any):void{
        const id=data.id;
        if(data.children){
            for(let i=0;i<data.children.length;i++){
                this.selAllPermission(data.children[i]);
            }
        }else{//叶子节点
            const menuItemPermission=JSON.parse(data.menuItemPermission);
            let permissionValue=[] as any;
            menuItemPermission.forEach((item:any,index:number)=>{
                permissionValue.push(item.code);
            },permissionValue);
            this.dataObj.menuSelPermissionItems[id]=permissionValue;
            this.dataObj.treeRef.setChecked(id,true);
        }
    }
    public cancelAllPermission(data:any):void{
        const id=data.id;
        if(data.children){
            for(let i=0;i<data.children.length;i++){
                this.cancelAllPermission(data.children[i]);
            }
        }else{
            this.dataObj.menuSelPermissionItems[id]='';
            this.dataObj.treeRef.setChecked(id,false);
        }
    }
    public getSaveData(menuSelPermissionItems:any,menuSelKeys:any):any{
        for (let key in menuSelPermissionItems) {
            if (menuSelKeys.indexOf(key) == -1) {
                delete menuSelPermissionItems[key];
            }
        }
        let menuPermissions = {} as any;
        for (let key in menuSelPermissionItems) {
            let perStr = "";
            for (let i = 0; i < menuSelPermissionItems[key].length; i++) {
                perStr = perStr + menuSelPermissionItems[key][i] + ",";
            }
            perStr = perStr.substr(0, perStr.length - 1);
            menuPermissions[key] = perStr;
        }
        return menuPermissions;
    }
}